import React from 'react';
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import './App.css';

import Keypad from "./components/keypad-panel/Keypad";
import { WelcomePage } from "./components/welcome-screen/WelcomePage";
import { GameContainer } from "./components/GameContainer/GameContainer";
import SafeDoor from "./components/safe-door/SafeDoor";

import backgroundImage from './assets/cave_background_01.jpg';
import {VolcanoGod} from "./components/volcano-god/VolcanoGod";
import {ElevatorPuzzle} from "./components/elevator-puzzle/ElevatorPuzzle";
function App() {



  // TODO: Global context like https://stackoverflow.com/questions/72474888/how-to-save-states-between-route-change-in-react
  // See https://reactrouter.com/en/main/routers/create-hash-router for why static is problematic.
  const router = createHashRouter([
    {
      path: "/",
      element: <WelcomePage/>,
    },
    {
      path: "game",
      element: <GameContainer/>,
    },
    {
      path: "keypad",
      element: <Keypad/>,
    },
    {
      path: "safedoor",
      element: <SafeDoor/>,
    },
    {
      path: "name",
      element: <VolcanoGod/>,
    },
    // {
    //   path: "name",
      // element: <ElevatorPuzzle/>,

    // },
  ]);

  return (
    <div className="App">
      <header className="App-header" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <RouterProvider router={router} />
      </header>
    </div>
  );
}

export default App;
