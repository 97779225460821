import React, {useState} from "react";

import button_01 from './assets/button_01.png';
import button_02 from './assets/button_02.png';
import button_03 from './assets/button_03.png';
import button_04 from './assets/button_04.png';
import button_05 from './assets/button_05.png';
import button_06 from './assets/button_06.png';
import button_07 from './assets/button_07.png';
import button_08 from './assets/button_08.png';
import button_09 from './assets/button_09.png';
import button_00 from './assets/button_00.png';
import button_asterisk from './assets/button_asterisk.png';
import button_pound from './assets/button_pound.png';

import button_01_pressed from './assets/button_01_pressed.png';
import button_02_pressed from './assets/button_02_pressed.png';
import button_03_pressed from './assets/button_03_pressed.png';
import button_04_pressed from './assets/button_04_pressed.png';
import button_05_pressed from './assets/button_05_pressed.png';
import button_06_pressed from './assets/button_06_pressed.png';
import button_07_pressed from './assets/button_07_pressed.png';
import button_08_pressed from './assets/button_08_pressed.png';
import button_09_pressed from './assets/button_09_pressed.png';
import button_00_pressed from './assets/button_00_pressed.png';
import button_asterisk_pressed from './assets/button_asterisk_pressed.png';
import button_pound_pressed from './assets/button_pound_pressed.png';

// This is called de-structing.
export const KeypadButton = ({buttonId, calculate}: { buttonId: string, calculate: (buttonId: string) => void; }) => {

    const imageDelayMillis = 250;
    const imageMap = buildImageMap();
    const [imageSource, setImageSource] = useState(imageMap.get(buttonId));

    const handleButtonClick = (e: React.MouseEvent, buttonId: string) => {

        setImageSource(imageMap.get(buttonId + '_pressed'));
        setTimeout(() => {
            setImageSource(imageMap.get(buttonId));
        }, imageDelayMillis);

        calculate(buttonId);
    }

    return (<span>
        <img src={imageSource} onClick={(e) => handleButtonClick(e, buttonId)} />
    </span>
    )
}

function buildImageMap(): Map<string, string> {
    const imageMap = new Map<string, string>([
        ['1', button_01],
        ['1_pressed', button_01_pressed],
        ['2', button_02],
        ['2_pressed', button_02_pressed],
        ['3', button_03],
        ['3_pressed', button_03_pressed],
        ['4', button_04],
        ['4_pressed', button_04_pressed],
        ['5', button_05],
        ['5_pressed', button_05_pressed],
        ['6', button_06],
        ['6_pressed', button_06_pressed],
        ['7', button_07],
        ['7_pressed', button_07_pressed],
        ['8', button_08],
        ['8_pressed', button_08_pressed],
        ['9', button_09],
        ['9_pressed', button_09_pressed],
        ['0', button_00],
        ['0_pressed', button_00_pressed],
        ['*', button_asterisk],
        ['*_pressed', button_asterisk_pressed],
        ['#', button_pound],
        ['#_pressed', button_pound_pressed],
    ]);

    return imageMap;
}
export default KeypadButton;