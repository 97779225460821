import './LavaTimer.css';
import {useEffect, useRef} from "react";
export const LavaTimer = () => {

  // React TypeScript (not JavaScript) requires <any> being added to avoid "Property does not exist on type 'never'."
  const canvasRef = useRef<HTMLCanvasElement>(null);


  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d')
      // do something here with the canvas
      let y = canvasRef.current.height;
      let dy = -2;
      let darkness = 255;
      let color = "#ff"+darkness.toString(16)+"33";
      let change_every = Math.round(canvasRef.current.height / 255);

      const draw = () => {
        // @ts-ignore
        ctx.beginPath();
        // @ts-ignore
        ctx.arc(25, y, 15, 0, Math.PI, true);
        // @ts-ignore
        ctx.fillStyle = color;
        // @ts-ignore
        ctx.fill();
        // @ts-ignore
        ctx.closePath();
      }

      const increase = () => {
        if ( y > 10 ) {
          draw();
        } else {
          // clearInterval(myInterval);
          alert("It's too late ..");
        }

        y += dy;
        color = "#ff"+darkness.toString(16)+"33";

        if ( y % change_every === 0 ) {
          darkness -= 2;
        }
      }
      // TODO: Update with On Mount see https://stackoverflow.com/questions/33924150/how-to-access-canvas-context-in-react
      // let myInterval = setInterval(increase, 100);
    }
  }, [])

  return (
    <>
      <canvas ref={canvasRef} ></canvas>
    </>
  );
};




