import './SafeDoor.css';

import {Slider} from "@mui/material";
import Box from '@mui/material/Box';
import React, {SyntheticEvent, useState} from "react";
import ModalImage from "react-modal-image";

import safe_locked_image from './assets/red_switch.png';
import safe_unlocked_image from './assets/green_switch.png';
import danger_sign_front from './assets/danger_chemical_storage_sign_534x397.png';
import danger_sign_back from './assets/danger_chemical_storage_sign_534x397_flipped.png';
import danger_sign_back_enlarged from './assets/danger_chemical_storage_sign_1200x892_flipped_large.png';

import failure_sound from './assets/failure_sound.mp3';
import single_click_sound from './assets/single_click_sound.mp3';
import vault_open_sound from './assets/vault_open_sound_delayed.mp3';
import paper_flip_1_sound from './assets/paper_flip.mp3';
import paper_flip_2_sound from './assets/paper_flip_2.mp3';

import './SafeDoor.css';
import success_sound from "../keypad-panel/assets/success_sound.mp3";

import backgroundImage from './assets/safe_background.png';

const SafeDoor = () => {

  const VISIBLE_CLASSNAME: string = 'visible';
  const HIDDEN_CLASSNAME: string = 'hidden'

  const [safeLockedImageSource, setSafeLockedImageSource] = useState(safe_locked_image);
  const [chemicalSignImageSource, setChemicalSignImageSource] = useState(danger_sign_front);
  const [smallImageClass, setSmallImageClass] = useState(VISIBLE_CLASSNAME);
  const [largeImageClass, setLargeImageClass] = useState(HIDDEN_CLASSNAME);

  const marks = [
    {value: 0, label: '0'},
    {value: 1, label: '1'},
    {value: 2, label: '2'},
    {value: 3, label: '3'},
    {value: 4, label: '4'},
    {value: 5, label: '5'},
    {value: 6, label: '6'},
    {value: 7, label: '7'},
    {value: 8, label: '8'},
    {value: 9, label: '9'},
  ];
  const sliders = [
    {id: 0, name: '0'},
    {id: 1, name: '1'},
    {id: 2, name: '2'},
    {id: 3, name: '3'},
    {id: 4, name: '4'},
    {id: 5, name: '5'},
  ];

  const attemptedSolution: Map<string, number> = new Map([
    ['0', 0],
    ['1', 0],
    ['2', 0],
    ['3', 0],
    ['4', 0],
    ['5', 0],
  ]);

  const solution: Map<string, number> = new Map([
    ['0', 1],
    ['1', 6],
    ['2', 8],
    ['3', 7],
    ['4', 2], // 2 = 1 & 1
    ['5', 8], // 8 = 1 & 7
  ]);

  const valuetext = (value: number) => {
    return `${value}`;
  }

  const handleSliderChange = (event: Event, value: number | Array<number>, activeThumb: number) => {
    let sliderValue = value instanceof Array ? value[0] : value;
    let sliderName: string = '';

    if (event && event.target && event?.target && 'name' in event?.target) {
      let name: string | undefined = event?.target?.name as string;
      if (name && name.length > 0) {
        sliderName = name as string;
      }
    }
    playButtonSound(single_click_sound);
    console.info('handleSliderChange SETTING sliderName: ' + sliderName + ' / sliderValue: ' + sliderValue);
    attemptedSolution.set(sliderName, sliderValue);
  }

  const handleSliderDragEnd = (event: Event | SyntheticEvent<Element, Event>, value: number | number[]) => {
    let sliderValue = value instanceof Array ? value[0] : value;
    let sliderName: string = '';
    if (event && event.target) {
      if (event.target && 'firstChild' in event.target) {
        let input: HTMLInputElement = event.target.firstChild as HTMLInputElement;
        if (input) {
          sliderName = input.name;
          // Important: If the user drags the mouse pointer off the button before releasing the mouse, the sliderName
          // will be undefined.
          if (sliderName) {
            attemptedSolution.set(sliderName, sliderValue);
            console.info('handleSliderDragEnd SETTING sliderName: ' + sliderName + ' / sliderValue: ' + sliderValue);
          }
        }
      }
    }
  }

  const checkSolution = () => {
    for (let key in ['0', '1', '2', '3', '4', '5']) {
      if (attemptedSolution.get(key) !== solution.get(key)) {
        playButtonSound(failure_sound);
        return false;
      }
    }
    setSafeLockedImageSource(safe_unlocked_image);
    playButtonSound(success_sound);
    playButtonSound(vault_open_sound);
  }

  const flipSign = () => {
    if (chemicalSignImageSource === danger_sign_front) {
      playButtonSound(paper_flip_2_sound);
      setChemicalSignImageSource(danger_sign_back);
      setSmallImageClass('hidden');
      setLargeImageClass('visible');
    } else {
      playButtonSound(paper_flip_1_sound);
      setChemicalSignImageSource(danger_sign_front);
      setSmallImageClass('visible');
      setLargeImageClass('hidden');
    }
  }

  return (
    <div className={'pageWrapper'} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className={'tableWrapper'}>
        <table className={'controls'}>
          <tbody>
          <tr>
            <td>
              <img
                src={chemicalSignImageSource}
                className={'danger-sign-image ' + smallImageClass}
                onClick={flipSign}
              />
              <ModalImage
                small={chemicalSignImageSource}
                smallSrcSet={chemicalSignImageSource}
                large={danger_sign_back_enlarged}
                hideDownload={false}
                hideZoom={true}
                className={largeImageClass}
              />
            </td>
            <td>
              {/* https://mui.com/material-ui/react-slider/ */}
              {sliders.map(
                x =>
                  <Box
                    sx={{
                      width: 300,
                      height: 85,
                      '& .MuiSlider-markLabel': {
                        color: 'rgba(255, 255, 255, .6)',
                        fontFamily: 'Courier, monospaced',
                        fontWeight: 'bold',
                        fontSize: '14pt'
                      },
                  }}
                    key={x.name}
                  >
                    <Slider
                      name={x.name}
                      itemID={x.name}
                      aria-label="Number Selector"
                      defaultValue={0}
                      getAriaValueText={valuetext}
                      step={1}
                      min={0}
                      max={9}
                      marks={marks}
                      valueLabelDisplay={'auto'}
                      onChange={handleSliderChange}
                      onChangeCommitted={handleSliderDragEnd}
                    />
                  </Box>
                )
              }
            </td>
            <td>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <img
                src={safeLockedImageSource}
                className={'safe-lock-image'}
                onClick={checkSolution}
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      </div>
  )
}

// TODO: Remove buttonId param
// TODO: Move to util class
/**
 *
 * @param buttonId
 * @param buttonSound
 */
const playButtonSound = (buttonSound: any) => {
  let audio = new Audio(buttonSound);
  audio.play().then(r => {});
}

export default SafeDoor;