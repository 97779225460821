import {forwardRef, useImperativeHandle, useState, useRef, Component} from "react";
import './PasswordBox.css';

export const PasswordBox = forwardRef((props, ref) => {

    const [displayedText, setDisplayedText] = useState<string>('');
    let storedText: string = '';

    useImperativeHandle(ref, () => ({
        /*
        TODO: Find out what this means.
        Assignments to the 'storedText' variable from inside React Hook useImperativeHandle
        will be lost after each render. To preserve the value over time, store it in a
        useRef Hook and keep the mutable value in the '.current' property. Otherwise,
        you can move this variable directly inside useImperativeHandle
         */
        setDisplayText(updatedDisplayText: string) {
            setDisplayedText(updatedDisplayText)
            // TODO: This is a kludge, because getDisplayText() would not return the value of {displayedText}.
            storedText = updatedDisplayText;
        },
        getDisplayText(): string {
            return storedText;
        },
    }), []);

        return (
        <>
            <div className={'box'}>{displayedText}</div>
        </>
    );
});

export default PasswordBox;