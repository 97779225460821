import {useRef, useState} from 'react';
import KeypadButton from '../keypad-button/KeypadButton';
import PasswordBox from '../password-box/PasswordBox';

import './Keypad.css';

// Aha! Had to put audio.d.ts file in root, containing "declare module '*.mp3'";
import default_button_sound from './assets/beep_micro.mp3'
import pound_button_sound from './assets/beep_entry.mp3';
import failure_sound from './assets/failure_sound.mp3';
import asterisk_button_sound from './assets/beep_final_processing.mp3';
import success_sound from './assets/success_sound.mp3';
import vault_open_sound from './assets/vault_open_sound_delayed.mp3';


import red_button_on_image from './assets/red-button-on.png';
import red_button_off_image from './assets/red-button-off.png';
import green_button_on_image from './assets/green-button-on.png';
import green_button_off_image from './assets/green-button-off.png';
import backgroundImage from "./assets/power_room_background.png";

const Keypad = () => {
  // React TypeScript (not JavaScript) requires <any> being added to avoid "Property does not exist on type 'never'."
  const passwordBoxRef = useRef<any>(null);

  const [redImageSource, setRedImageSource] = useState(red_button_on_image);
  const [greenImageSource, setGreenImageSource] = useState(green_button_off_image);
  const [solved, setSolved] = useState(false);

  const maxLength = 17;
  const passcodeLength = 5;
  const PASSWORD_MASK: string = '\u2022';
  const password: string = generatePasscode(passcodeLength);

  let characterIndex = 0;
  let lockOpened = solved; // TODO: Fix having two variables here.

  const processButtonPresses = (buttonId: string) => {
    // If the puzzle is solved, do nothing
    if (lockOpened) {
      return;
    }

    // If we are at max length and reset is not pressed, do nothing
    if (passwordBoxRef.current.getDisplayText().length === maxLength && buttonId !== '*') {
      return;
    }

    let buttonSound = null;
    // Taking this out for now. # and * will both reset.
    // if (buttonId === '#') {
    //   buttonSound = pound_button_sound;
    //   playButtonSound(buttonId, buttonSound);
    //   return;
    // }
    if (buttonId === '*' || buttonId === '#') { // Reset
      // If nothing has been entered yet, do nothing.
      if (passwordBoxRef.current.getDisplayText().length === 0) {
        return;
      }
      resetUserEntry();
      buttonSound = asterisk_button_sound;
      playButtonSound(buttonId, buttonSound);
      return;
    }

    // Correct answers
    if (buttonId === password[characterIndex]) {
      playButtonSound(buttonId, default_button_sound);
      passwordBoxRef.current.setDisplayText(passwordBoxRef.current.getDisplayText() + PASSWORD_MASK);
      characterIndex++;
    // Nope, wrong
    } else {
      resetUserEntry()
      playButtonSound(buttonId, failure_sound);
      return;
    }

    // Completed
    if (characterIndex === password.length) {
      // TODO: Possible to store state without triggering re-render? Because changing the images below causes the
      // password to reset and the variable 'lockOpened' to reset to false.
      setSolved(true);
      setRedImageSource(red_button_off_image);
      setGreenImageSource(green_button_on_image);
      playButtonSound('', success_sound, 5);
      playButtonSound('', vault_open_sound);
    }
  }

  // TODO: Remove buttonId param
  // TODO: Move to util class
  /**
   *
   * @param buttonId
   * @param buttonSound
   */
  const playButtonSound = (buttonId: string, buttonSound: any, delaySeconds: number = 0) => {
    let audio = new Audio(buttonSound);
    audio.play().then(r => {});
    delay(delaySeconds);
  }

  const resetUserEntry = () => {
    passwordBoxRef.current.setDisplayText('');
    characterIndex = 0;
  }

  return (
    <>
      <div className={'keypad-wrapper'} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <table className={'keypad-table'}>
        <tbody>
        <tr>
          <td>
            <PasswordBox ref={passwordBoxRef}/>

            <br />

            <div className={'keys'}>
              {/* Consider looping this construction */}
              <KeypadButton buttonId={'1'} calculate={processButtonPresses}/>
              <KeypadButton buttonId={'2'} calculate={processButtonPresses}/>
              <KeypadButton buttonId={'3'} calculate={processButtonPresses}/>

              <br/>

              <KeypadButton buttonId={'4'} calculate={processButtonPresses}/>
              <KeypadButton buttonId={'5'} calculate={processButtonPresses}/>
              <KeypadButton buttonId={'6'} calculate={processButtonPresses}/>

              <br/>

              <KeypadButton buttonId={'7'} calculate={processButtonPresses}/>
              <KeypadButton buttonId={'8'} calculate={processButtonPresses}/>
              <KeypadButton buttonId={'9'} calculate={processButtonPresses}/>

              <br/>

              <KeypadButton buttonId={'*'} calculate={processButtonPresses}/>
              <KeypadButton buttonId={'0'} calculate={processButtonPresses}/>
              <KeypadButton buttonId={'#'} calculate={processButtonPresses}/>

              <br/>
            </div>
          </td>
          <td>
            &nbsp;&nbsp;&nbsp;
          </td>
          <td>
            <img src={redImageSource}/>
            <br/><br/>
            <img src={greenImageSource}/>
          </td>
        </tr>
        </tbody>
      </table>
      </div>
    </>
  );

}

/**
 * Doesn't work. Want to be able to delay sounds.
 * @param seconds
 */
const delay = (seconds: number) => {
    setTimeout(() => {
        return;
    }, 1000 * seconds);
}

/**
 * TODO: Move to Util Class
 */
const generatePasscode = (passcodeLength: number) => {
  let pwd = ''
  const passwordChars = '0123456789';
  for (let i = 0; i < passcodeLength; i++) {
    pwd += passwordChars[truePseudoRandom()];
  }
  // console.log('assembled passcode:' + pwd)
  return pwd;
}

/**
 * TODO: Move to Util Class
 */
const truePseudoRandom = () => {
  let rndArr = new Uint32Array(1);
  window.crypto.getRandomValues(rndArr);
  const random = rndArr[0] / (0xffffffff + 1);
  const num = Math.floor(random * (9 - 0 + 1)) + 0;
  return num;
}

export default Keypad;