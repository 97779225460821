import './VolcanoGod.css';
import backgroundImage from './assets/surtr_fire_lava_god_03.png';

export const VolcanoGod = () => {

  return (
    <>
      <div className={'page-wrapper'} style={{ backgroundImage: `url(${backgroundImage})` }}>
        <p className={'page-inner'}>&nbsp;</p>
      </div>
    </>
  );
};
