import {LavaTimer} from "../lava-timer/LavaTimer";
import './GameContainer.css';

export const GameContainer = () => {
  return (
    <>
      <div id={'game'} className={'game'}>game area</div>
      <div id={'timer'} className={'timer'}>
        <LavaTimer/>
      </div>
      <div id={'footer'} className={'footer'}>Map</div>
    </>
  );
};