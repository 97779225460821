import './WelcomePage.css';

import posterImage from './assets/escape_poster.png';
export const WelcomePage = () => {
  return (
    <>
      <div>
        <img src={posterImage} />
      </div>

    </>
  );
};